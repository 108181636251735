<template>
  <div>
    <CardItemContainer
      title="Ways to Redeem"
      :items="redeemList.map(res => ({...res, styles: setThemeStyle()}))"
      class="redeem"
    >
      <template v-slot:default="slotProps" >
        <Badge :style="{'backgroundColor':slotProps.item.styles.color,'color':slotProps.item.styles.fontColor}"
          :class="{'badge-animate': true}"
          @click="() => redeemReward(slotProps.item)"
          v-if="isEnoughPoints(slotProps.item.points)"
        >
          {{ '-'+slotProps.item.points }}
        </Badge>
      </template>
    </CardItemContainer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { hexToRgb } from '../util';
import reponseState from '../store/responseState';
import CardItemContainer from '../components/CardItemContainer.vue';
import Badge from '../components/Badge.vue';
export default {
  setup(props) {},
  mounted() {
    this.$api.redeemways().then((res) => {
      this.redeemInfo(res);
      this.spinLoading(false);
    });
  },
  methods: {
    ...mapActions(["redeemInfo", "spinLoading","rewardDetail",'widgetSetting']),
    redeemReward: function(item){
      if(item.points > this.userObj.points) {
        alert('Sorry, there is not enough points for you to reddem this coupon. ')
      }else {
        this.$api.redeemRewards(item.redeemRuleId).then((res)=>{
          if(res.data.success==reponseState.SUCCESS_CODE){
            this.$api.widget().then(this.widgetSetting);
            this.rewardDetail({
              itemTit: 'off coupon',
              itemSubtit: 'spent '+item.subtitle,
              currency: item.tit.split(' ')[0],
              icon: item.icon,
              styles: this.setThemeStyle(),
              discountCode: res.data.data.discountCode,
            });
            this.$router.push({
              name: 'RewardDetail',
            });
          }else{
            alert("We encountered an internal error. Please try again")
          }
        });
      }
    },
    setThemeStyle() {
      return {
        color:this.themeColor,
        fontColor: this.fontColor,
        backgroundColor: this.convertBackgroundColor(this.themeColor),
      }
    },  
    convertBackgroundColor(color) {
      const rgb = hexToRgb(color);
      return `rgba(${rgb.r} ${rgb.g} ${rgb.b} /0.1)`;
    },
    isEnoughPoints(item) {
      return this.hadLogin && this.userObj.points >= item
    }
  },
  computed: {
    ...mapGetters({
      userObj: "userObj",
      hadLogin: "hadLogin",
      redeemList: "redeemList",
      loading: "loading",
      themeColor: "themeColor",
      fontColor: "fontColor"
    }),
  },
  components: {
    CardItemContainer,
    Badge,
  },
};
</script>
<style scoped>
.redeem {
  min-height: 445px;
}
.redeem ::v-deep(.container-list) {
  background-color: #fff !important;
  border-bottom: none;
}
.redeem ::v-deep(.container-center .tit) {
  color: #0E0300FF;
  font-size: 15px;
}
.redeem ::v-deep(.container-center .sub-tit) {
  font-size: 11px;
  color: #B7B7B7FF;
  width: 140px;
}
.redeem ::v-deep(.container-center .tit) {
  width: 140px;
}
.redeem ::v-deep(.badge-container) {
  width: 80px;
  font-size: 15px;
}
.redeem ::v-deep(.badge-animate) {
  animation: all .6s;
}
</style>
